'use strict';

import objectFitImages from 'object-fit-images';
import smoothscroll from 'smoothscroll-polyfill';

class Common {
  init() {
    window.addEventListener(
      'load',
      () => {
        this.toggleMenu();
        this.current();
        this.scrollToId();
        objectFitImages('.object-fit');
        smoothscroll.polyfill();
      },
      false,
    );
  }

  toggleMenu() {
    const gnavi = document.querySelector('.gnavi');
    const gnaviMenu = document.querySelector('.gnavi__menu');
    const menuItemHusSub = document.querySelector('.gnavi__menu-item.has--sub');
    const subMenu = document.querySelector('.gnavi__submenu');
    const subMenuItems = document.querySelectorAll('.gnavi__submenu__list-item');
    const subMenuCloseBtn = document.querySelector('.gnavi__submenu__close a');
    const spMenuCloseBtnArea = document.querySelector('.gnavi__button');
    const spMenuCloseBtn = document.querySelector('.gnavi__button button');
    const spMenuCloseBtnBottom = document.querySelector('.gnavi__close');

    const toggleAttr = (elm, attrSuffix) => {
      let attr = elm.getAttribute('aria-' + attrSuffix);
      return attr == 'true' ? 'false' : 'true';
    }

    const toggleSubMenu = () => {
      menuItemHusSub.setAttribute('aria-expanded', toggleAttr(menuItemHusSub, 'expanded'));
      subMenu.setAttribute('aria-hidden', toggleAttr(subMenu, 'hidden'));
    }

    const toggleSpMenu = () => {
      spMenuCloseBtn.setAttribute('aria-expanded', toggleAttr(spMenuCloseBtn, 'expanded'));
      spMenuCloseBtnBottom.setAttribute('aria-expanded', toggleAttr(spMenuCloseBtnBottom, 'expanded'));
      gnaviMenu.setAttribute('aria-hidden', toggleAttr(gnaviMenu, 'hidden'));
      if (spMenuCloseBtn.getAttribute('aria-expanded') == 'true') {
        spMenuCloseBtnArea.style.backgroundColor = '#666';
        gnavi.classList.add('is-active');
      } else {
        spMenuCloseBtnArea.style.backgroundColor = 'transparent';
        gnavi.classList.remove('is-active');
      }
    }

    menuItemHusSub.addEventListener('click', (e) => {
      toggleSubMenu();
      e.stopPropagation();
    });

    subMenuCloseBtn.addEventListener('click', (e) => {
      toggleSubMenu();
      e.stopPropagation();
    });

    spMenuCloseBtn.addEventListener('click', (e) => {
      toggleSpMenu();
      e.stopPropagation();
    });

    spMenuCloseBtnBottom.addEventListener('click', (e) => {
      toggleSpMenu();
      e.stopPropagation();
    });

    for (let i = 0; i < subMenuItems.length; i++) {
      subMenuItems[i].addEventListener('click', (e) => {
        e.stopPropagation();
      });
    }
  }

  current() {
    const solution = ['additive-manufacturing', 'subtractive-processing', 'riblet-processing', 'robot-vision-system'];
    const menuItemLinks = document.querySelectorAll('.gnavi__menu-item__link');
    const dir = location.pathname.split('/');
    const dirName = dir[1] == 'en' ? dir[2] : dir[1];

    if (dirName != '') {
      const currentPage = solution.indexOf(dirName) >= 0 ? 'solution' : dirName;
      for (let i = 0; i < menuItemLinks.length; i++) {
        if (menuItemLinks[i].classList.contains(currentPage)) {
          menuItemLinks[i].classList.add('is-active');
        }
      }
    }
  }

  scrollToId() {
    const anchorLinks = document.querySelectorAll('a[href^="#"]');

    if (anchorLinks) {
      for (let i = 0; i < anchorLinks.length; i++) {
        const targetId = anchorLinks[i].getAttribute('href')
        if (targetId != '#') {
          const target = document.querySelector(targetId);
          anchorLinks[i].addEventListener('click', (e) => {
            target.scrollIntoView({
              behavior: 'smooth',
            });
            e.preventDefault();
          });
        }
      }
    }
  }
}
const common = new Common();
common.init();